import Base from '@/libs/core/Base'
import Vault from '@/libs/core/Vault'

const fields = {
  distribution_point_uid: null,
  order_items: [],
  // customer_uid: Vault.take('client').client_uid,
}

export default class Order extends Base {
  constructor () {
    super(fields)
  }

  store () {
    const data = this.getFields()
    return new Promise(async (resolve, reject) => {
      try {
        let response = await this.form.submit('post', 'retail/orders', data)
        this.setFields(fields)
        flash(response)
        resolve(response)
      } catch (err) {
        reject(err)
      }
    })
  }
}
