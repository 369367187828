<template>
  <base-dialog-form
    :width="450"
    :dialog="dialog"
    :loading="loading"
    title="Place an order"
    actionText="Place order"
    @close="$emit('close')"
    @submit="submit()"
  >
    <template #content>
      <order-distribution-point
        :errors="errors"
        @clear="errors.clear('distribution_point_uid')"
      />

      <order-items 
        v-if="distributionPointUid"
      />

      <!-- <order-total
      
      /> -->
    </template>
  </base-dialog-form>
</template>

<script>
import * as _ from 'lodash'
import Order from '@/libs/retail/Order'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    dialog: {
      required: true,
      type: Boolean,
    },
  },

  components: {
    'order-distribution-point': () => import('./OrderDistributionPoint.vue'),
    'order-items': () => import('./OrderItems.vue'),
    'order-total': () => import('./OrderTotal.vue'),
  },

  data () {
    return {
      loading: false,
      orderObj: new Order(),
    }
  },

  computed: {
    ...mapGetters('retail', {
      distributionPointUid: 'getDistributionPointUid',
      orderItems: 'getOrderItems',
    }),

    errors () {
      return this.orderObj.form.errors
    },
  },

  methods: {
    ...mapActions([
      'setRetailOrders'
    ]),

    submit () {
      if (!this.loading) {
        this.loading = true

        this.orderObj.distribution_point_uid = this.distributionPointUid
        this.orderObj.order_items = this.orderItems
        
        this.orderObj.store().then(() => {
          this.setRetailOrders().then(() => {
            this.$emit('close')
          })
        })
        .finally(() => {
          this.loading = false
        })
      }
    },
  },
}
</script>